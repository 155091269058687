<template lang="pug">
q-dialog(
    :model-value="showAnalogsDialog"
    @hide="closeAnalogsDialog")
    .b-radius-16.bg-secondary.q-py-xl.q-pl-xl.q-pr-lg.relative-position(
        :style="`width: 1096px; max-width: 1096px`"
    )
        .font-20.text-primary.line-height-32.text-weight-medium.q-mb-lg
            | Аналоги для {{ title }}
        .row.absolute-top-right.q-pt-sm.q-pr-sm(style="z-index: 1;")
            q-btn(
                flat
                round
                fab-mini
                @click="closeAnalogsDialog")
                q-icon(
                    name="etabl:cross"
                    size="xs"
                    color="grey-4")
        q-scroll-area(:style="`height: ${modalHeight}px; width: 1024px`")
            .row.q-pr-xl
                template(v-for="(product, index) in analogs")
                    .q-pb-md.product-card(
                        :class="`product-card-4`")
                        preview-desktop(
                            :data="product"
                            :topOffset="!index"
                            :country="true"
                            @showAnalogsList="showAnalogsList")
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import PreviewDesktop from '../Preview/PreviewDesktop.vue';

export default {
    name: 'AnalogsMobile',
    components: {
        PreviewDesktop,
    },
    props: {
        showAnalogsDialog: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const store = useStore();

        const analogs = computed(() => store.getters['products/PRODUCT_ANALOGS']);
        const title = computed(() => store.getters['products/CURRENT_PRODUCT_ANALOGS']);

        const modalHeight = computed(() => {
            if (process.env.CLIENT) {
                if(analogs.value.length < 5) {
                    return '460';
                } 
                if(analogs.value.length < 9) {
                    if(window.innerHeight > 960) {
                        return '920';
                    } else {
                        return '756';
                    }
                } 
                if(window.innerHeight < 960) {
                    return '756';
                }
                if(window.innerHeight < 1070) {
                    return '860';
                }
                if(window.innerHeight < 1200) {
                    return '970';
                }
            }

            return '1024';
        });

        const closeAnalogsDialog = () => {
            emit('closeAnalogsDialog');
        };

        return {
            analogs,
            closeAnalogsDialog,
            title,
            modalHeight,
        };
    },
};
</script>

<style lang="scss" scoped>
.product-card {
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;

    &-4 {
        width: 25%;

        @media (max-width: 1280px) {
            width: 33%;
        }
        @media (max-width: 960px) {
            width: 50%;
        }

        &:first-child,
        &:nth-child(4n + 5) {
            padding-left: 0;

            @media (max-width: 1280px) {
                padding-left: 8px;
            }
        }

        &:nth-child(4n + 4) {
            padding-right: 0;

            @media screen and (max-width: 1280px) {
                padding-right: 8px;
            }
        }
    }

    &-5 {
        width: 20%;

        &:first-child,
        &:nth-child(5n + 6) {
            padding-left: 0;
        }

        &:nth-child(5n + 5) {
            padding-right: 0;
        }
    }
}

.q-scrollarea :deep(.absolute) {
    width: 100%;
}
</style>