<template lang="pug">
q-tooltip(
    :class="[fss ? 'custom' : '', basket ? 'basket-tooltip' : '', 'bg-secondary', 'text-positive', 'text-weight-medium', recipe ? 'recipe-tooltip' : '', tooltip300 ? 'tooltip-300' : '']"
    transition-show="scale"
    transition-hide="scale"
    :offset="offset")
    | {{ title }}
    q-icon.q-ml-xs(
        v-if="icon"
        size="16px"
        name="etabl:ruble"
        color="positive"
    )
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'BaseTooltipe',
    props: {
        title: {
            type: String,
        },
        icon: {
            type: Boolean,
            default: () => false,
        },
        fss: {
            type: Boolean,
            default: () => false,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        offset: {
            type: Array,
            default: () => [10, 10],
        },
        recipe: {
            type: Boolean,
            default: () => false,
        },
        tooltip300: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>

<style lang="scss">
.q-tooltip--style {
    font: {
      size: 12px;
      weight: 600;
    }
    color: $positive;
    background: $secondary;
    box-shadow: 0px 7px 20px rgba(53, 53, 53, 0.15);
    max-width: 500px;

    @media (max-width: 1280px) {
        font-size: 10px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 12px;
    }


}

.recipe-tooltip {
    color: $secondary !important;
    background: $deep-purple-1 !important;
    opacity: 0.9;
    max-width: 400px !important;
}

.tooltip-300 {
    max-width: 300px !important;
}

// .custom {
//     max-width: 320px !important;
//     text-align: center;
// }

// .basket-tooltip {
//     font: {
//       size: 14px;
//       weight: 400;
//     }
//     color: $grey-9;
//     max-width: 326px;
//     padding: 16px;
// }
</style>
